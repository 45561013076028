import React from 'react'
import styled from 'styled-components'

import { SectionTitle, MarkdownContent, SectionButtonWrapper } from "../../components/Section"
import { Button } from "../../components/FormStyle"

const Wrap = styled.div`
	background: #333;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0.1em, transparent 0.5px);
  background-size: 3em 3em;
`
const Section = styled.div`
	background: rgba(30, 65, 86, 0.9);
	padding: 150px 0;
	@media(max-width: 768px) {
		padding: 100px 0;
	}
`
const StepGrid = styled.div`
	margin: auto;
	margin-top: 150px;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	max-width: 1120px;
	width: 100%;
	@media(max-width: 768px){ 
		margin-top: 80px;
		grid-template-columns: repeat(2, 1fr);
	}
	@media(max-width: 500px){
		grid-template-columns: repeat(1, 1fr);
	}
`
const StepItem = styled.div`
	border-left: 1px solid rgba(255,255, 255, 0.3);
	margin-top: ${props=>props.mt};
	padding: 5px 29px;
	& h3 {
		color: rgba(255,255, 255, 0.3);
		text-align: left;
		font-size: 64px;
		line-height: 80px;
	}
	& p {
		color: white;
		text-align: left;
	}
	@media(max-width: 768px){
		margin: 0px 20px 50px 20px; 
	}
`
class HowToApplyRto extends React.Component {
	constructor(props) {
		super(props)
		this.applyClick = this.applyClick.bind(this);
	}
	applyClick() {
  	const { applyCallBack } = this.props;
  	if (applyCallBack) {
      applyCallBack();
    }
  }
	render() {
		const { data } = this.props
		return(
		<Wrap>
			<Section>
				<SectionTitle color="white">{data.title}</SectionTitle>
				<p><MarkdownContent textAlign="center" color="white" dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} /></p>
				<div className="container">
					<StepGrid>
						{
							data.features.map((item, i)=>{
								if(i===0 || i===2) {
									return(
										<div>
											<StepItem mb="30px">
												<h3>0{i+1}</h3>
												<p>{item.content}</p>
											</StepItem>
										</div>
									)
								} else {
									return(
										<div>
											<StepItem mt="100px">
												<h3>0{i+1}</h3>
												<p>{item.content}</p>
											</StepItem>
										</div>
									)
								}
							})
						}
					</StepGrid>
				</div>
				<SectionButtonWrapper mt="121px">
					<Button onClick={this.applyClick} width="300px" mWidth="250px"><div>Apply Now</div></Button>
				</SectionButtonWrapper>
			</Section>
		</Wrap>
		)
	}
}

export default HowToApplyRto