import React from 'react'

import { Section, SectionTitle, MarkdownContent, LeftTriangle, RightTriangle } from "../../components/Section"
import RtoForm from "../../components/RtoForm"

class RtoFormSection extends React.Component {
	render() {
		const { data, location } = this.props
		return(
			<Section bg="#F4FBFF" pt="132px" pb="0" mbp="0" mplr="0">
				<div className="container">
					<SectionTitle>{data.title}</SectionTitle>
					<p><MarkdownContent textAlign="center" color="#010920" dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} /></p>
					<RtoForm location={location} />
				</div>
				<LeftTriangle />
				<RightTriangle />
			</Section>
		)
	}
} 

export default RtoFormSection