import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { BreadCrumb, MarkdownContent } from "../../components/Section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import { TwoColumns } from '../../components/Grid'

import RightImage from "../../images/rto-process.png"

import BenefitCarousel from "../../components/BenefitCarousel"

const Wrapper = styled.div`
	padding-top: 271px;
	padding-bottom: 93px;
	background: #F4FBFF;
	& h1 {
		color: #062C44;
		margin-bottom: 0;
	}
	& p {
		margin-top: 11px;
	}
	@media(max-width: 1200px) {
		padding-top: 200px;
	}
`
const TextWrap = styled.div`
	width: 100%;
	margin-right: calc(50% - 775px);
	z-index: 11;
`
const ImageWrap = styled.div`
	height: 400px;
	z-index: 10;
	margin-top: -50px;
	background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 80%, #F4FBFF 100%), 
							linear-gradient(to left, rgba(255, 255, 255, 0) 80%, #F4FBFF 100%), 
							linear-gradient(to top, rgba(255, 255, 255, 0) 80%, #F4FBFF 100%), 
							linear-gradient(to right, rgba(255, 255, 255, 0) 70%, #F4FBFF 100%), 
							url(${RightImage}) top center;
	background-size: cover;
	@media(max-width: 768px) {
		display: none;
	}
`
const Benefits = styled.div`
	margin-top: 132px;
	& h2 {
		margin-bottom: 12px;
		color: #062C44;
	}
	& h3 {
		color: #062C44;
	}
	@media(max-width: 480px) {
		margin-top: 60px;
	}
`

const ApplyButton = styled.button`
	border: 0px;
	background: rgba(255, 255, 255, 0.0001);
	color: #ED602B;
	transition: ease 0.5s;
	& svg {
		margin-left: 10px;
		transform: none !important;
		transition: ease 0.3s;
		width: 25px;
	}
	& :hover {
		cursor: pointer;
		text-decoration: none;
		& svg {
			margin-left: 20px;
		}
	}
`
class RtoHero extends React.Component {
	constructor(props) {
		super(props)
		this.applyClicked = this.applyClicked.bind(this);
	}
	applyClicked() {
		const { applyCallBack } = this.props
		applyCallBack();
	}
	render() {
		const { data, subTitle } = this.props
		return (
			<Wrapper>
				<div className="container">
					<BreadCrumb top="-60px"><Link to="/">Home</Link> / <Link to="/buying-guide">Buying Guide</Link> / <span>Financing Process </span></BreadCrumb>
					<TwoColumns>
						<TextWrap>
							<h1>{data.title}</h1>
							<MarkdownContent dangerouslySetInnerHTML={{ __html: data.description.childMarkdownRemark.html }} />
							<div style={{ marginTop: 21 }}>
								<ApplyButton onClick={this.applyClicked}>Apply Now<ArrowIcon fill="#ED602B" /></ApplyButton>
							</div>
						</TextWrap>
						<ImageWrap />
					</TwoColumns>
					<Benefits>
						<h2>{subTitle}</h2>
						<BenefitCarousel data={data.features} />
					</Benefits>
				</div>
			</Wrapper>
		)
	}
}


export default RtoHero