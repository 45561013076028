import React  from 'react';
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CallToAction from "../../components/CallToAction"
import scrollToRef from '../../components/scrollToRef'

import RtoHero from '../../sections/BuyingGuide/RtoHero'
import HowToApplyRto from "../../sections/BuyingGuide/HowToApplyRto"
import WhyChooseRto from "../../sections/BuyingGuide/WhyChooseRto"
import RtoFormSection from "../../sections/BuyingGuide/RtoFormSection"
import TopSellingProducts from "../../sections/BuyingGuide/TopSellingProducts"

const RtoProcessPage = ({ data, location }) => {
	const  pageContent = data.allContentfulFinancingPage.edges[0].node
	const productData = data.allContentfulProduct
	const applyRef = React.createRef();
	const applyNav = () => {
      scrollToRef(applyRef);
  }
	return(
		<Layout location={location}>
      <SEO title={pageContent.metaTitle} description={pageContent.metaDescription.metaDescription} />
      <RtoHero data={pageContent.heroSection} subTitle={pageContent.heroSubTitle} applyCallBack={applyNav}/>
      <HowToApplyRto data={pageContent.applySection} applyCallBack={applyNav}/>
      <WhyChooseRto data={pageContent.availableOption} applyCallBack={applyNav} />
      <div ref={applyRef} />
      <RtoFormSection data={pageContent.formSection}  location={location} />
      <CallToAction data={pageContent.ctaSection}/>
      <TopSellingProducts data={pageContent.cardSection} products={productData} location={location} /> 
    </Layout>
	)
}

export default RtoProcessPage

export const pageQuery = graphql`
  query RtoProcessPageQuery {
  	allContentfulFinancingPage(filter: {title: {eq: "RTO Process"}}) {
	    edges {
	      node {
	        title
	        metaTitle
	        metaDescription {
	          metaDescription
	        }
	        heroSection {
	          title
	          description {
	            childMarkdownRemark {
	              html
	            }
	          }
	          features {
	            icon
	            title
	            description
	          }
	        }
	        heroSubTitle
	        applySection {
	          title
	          description {
	            childMarkdownRemark {
	              html
	            }
	          }
	          features {
	            content
	          }
	        }
	        availableOption {
	          title
	          description {
	            childMarkdownRemark {
	              html
	            }
	          }
	        }
	        formSection {
	          title
	          description {
	            childMarkdownRemark {
	              html
	            }
	          }
	        }
	        ctaSection {
	          title
	          description {
	            description
	          }
	        }
	        cardSection {
	          title
	          description {
	            childMarkdownRemark {
	              html
	            }
	          }
	        }
	      }
	    }
	  }
	  allContentfulProduct(sort: {fields: numberOfSale}){
      edges {
        node {
        	id
					skuNumber
					imageSrc
          image {
            fluid(maxWidth: 700, quality: 100) {
              base64
              sizes
              aspectRatio
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          width
          length
          height
          price
          priceDescription
          productServices {
            service
            image {
              fluid(maxWidth: 45, quality: 100) {
                aspectRatio
                base64
                sizes
                src
                srcWebp
                srcSetWebp
                srcSet
              }
            }
          }
          productRoofType {
            name
          }
          productCategory {
            name
          }
          numberOfSale
          canBeUsedAs {
            content
          }
        }
      }
    }
  }
`