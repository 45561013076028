import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Section, SectionTitle, MarkdownContent, SectionButtonWrapper } from "../../components/Section"
import { Button } from "../../components/FormStyle"
import { TwoColumns } from '../../components/Grid'

const Wrap = styled.div`
	max-width: 1120px;
	width: 100%;
	margin: auto;
`

const RTOChooseImage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "rto-choose.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} alt='RTO Choose' />
}


class WhyChooseRto extends React.Component {
	constructor(props) {
		super(props)
		this.applyClick = this.applyClick.bind(this);
	}
	applyClick() {
  	const { applyCallBack } = this.props;
  	if (applyCallBack) {
      applyCallBack();
    }
  }
	render() {
		const { data } = this.props
		return(
			<Section pt="161px" pb="148px">
				<SectionTitle>{data.title}</SectionTitle>
				<div className="container">
					<Wrap>
						<TwoColumns>
							<div>
								<MarkdownContent dangerouslySetInnerHTML={{__html: data.description.childMarkdownRemark.html}} />
							</div>
							<div>
								<RTOChooseImage />
							</div>
						</TwoColumns>
						<SectionButtonWrapper mt="104px">
							<Button onClick={this.applyClick} width="300px" mWidth="250px"><div>Get Started</div></Button>
						</SectionButtonWrapper>
					</Wrap>
				</div>
			</Section>
		)
	}
}

export default WhyChooseRto